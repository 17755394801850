import Helmet from 'react-helmet';
import React, { Component } from 'react';

import AccountFrame from './AccountFrame';
import CustomerForm from './forms/Customer';
import LoyaltyForm from './forms/Loyalty';
import PreferencesForm from './forms/Preferences';
import OrderWriterForm from './forms/OrderWriter';
import DefaultAddressForm from './forms/DefaultAddress';
import Login from './helpers/Login';
import withAccount from '../../containers/plugins/withAccount';
import { getCmsValue } from '../../utils/utils';

import 'react-phone-number-input/style.css';

class Profile extends Component {
  componentDidMount() {
    const { accessToken, getAccount } = this.props;

    if (accessToken) getAccount();
  }

  componentDidUpdate(prevProps) {
    const { accessToken, getAccount } = this.props;

    if (accessToken && accessToken !== prevProps.accessToken) getAccount();
  }

  render() {
    const { account, accessToken, site } = this.props;

    return (
      <AccountFrame
        title={getCmsValue(site.strings.accountPage__profile__title, site)}
        {...this.props}
      >
        {accessToken && account ? (
          <div className="account">
            <div className="account-double">
              <CustomerForm account={account} site={site} />
              <LoyaltyForm account={account} site={site} />
            </div>
            <PreferencesForm account={account} site={site} />
            <DefaultAddressForm account={account} site={site} />
            {Array.isArray(account?.tags) &&
              account.tags.includes('PROSTAFF__EMEA') && (
                <OrderWriterForm account={account} site={site} />
              )}
          </div>
        ) : (
          <Login site={site} />
        )}
      </AccountFrame>
    );
  }
}

export default withAccount(Profile);
