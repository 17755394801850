import React, { Component } from 'react';

import axios from 'axios';
import withAccount from '../../../containers/plugins/withAccount';

class OrderWriter extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.initialState = {
      loading: false,
      status: '',
    };

    this.state = {
      ...this.initialState,
    };
  }

  async onSubmit(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const params = new URLSearchParams({
      email: this.props.account.email,
    }).toString();

    const result = await axios
      .post(`/api/order-writer?${params}`)
      .then((res) => res.data);

    if (result !== 200) {
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
        status: 'Something went wrong. Please reach out to your representative',
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
        status:
          'You will receive an email with your order form within 10 minutes',
      }));
    }
  }

  render() {
    return (
      <div className="account-section account-section__prostaff">
        <div className="account-section__title">
          <h3>PRO STAFF</h3>
        </div>
        <div className="account-section__form">
          <form className="account-section__form" onSubmit={this.onSubmit}>
            <div className="form__input-wrapper">
              <p>
                For Pro Staff located in regions where webshop shipments are not
                currently enabled, you can click the "GET ORDER FORM" button
                below to get an order form sent to your email.
              </p>
            </div>
            <div className="form__submit-wrapper">
              {!this.state.loading && <span>{this.state.status}</span>}
              <button type="submit" disabled={this.state.loading}>
                GET ORDER FORM
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withAccount(OrderWriter);
